$base-font:'Montserrat', sans-serif;

$white: #fff;
$black: #000;
$base: #000;
$alt: #5087da;
// $alt-light: #9DD4FF;
$alt-light: #6dcff6;
$gray: #cccccc;
$lgray: #f2f2f2;

$light: 300;
$normal: 400;
$medium: 500;
$sbold: 600;
$bold: 700;